import React from "react";
import { animateScroll } from "react-scroll";

import Block from "components/Block";
import ContentGrid from "components/ContentGrid";

import "./ScrollToTop.scss";

export function ScrollToTopButton({ modifier = ["normal"] }) {
  const modifierClasses = modifier
    .map((mod) => `scroll-to-top__button--${mod}`)
    .join(" ");
  return (
    <button
      className={`scroll-to-top__button ${modifierClasses}`}
      type="button"
      onClick={() => animateScroll.scrollTo(0)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.4"
        height="11.1"
        viewBox="0 0 17.4 11.1"
      >
        <path
          fill="#fd8300"
          d="M8.7 0l8.7 8.552-2.591 2.548-6.109-6-6.109 6L0 8.552z"
        ></path>
      </svg>
      BACK TO TOP
    </button>
  );
}

export default function ScrollToTop({ modifier = ["normal"], guides = 1 }) {
  const modifierClasses = modifier
    .map((mod) => `scroll-to-top--${mod}`)
    .join(" ");
  return (
    <>
      <div className={`scroll-to-top ${modifierClasses}`}>
        <Block layer="1" guides={guides} appearance="scrolltop">
          <ContentGrid appearance="scrolltop">
            <div className="scroll-to-top__wrapper">
              <ScrollToTopButton modifier={modifier} />
            </div>
          </ContentGrid>
        </Block>
      </div>
    </>
  );
}
