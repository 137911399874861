import React from "react";
import "./ContentGrid.scss";

export default function ContentGrid({
  children,
  appearance = "default",
  ...props
}) {
  return (
    <div className={`content-grid content-grid--${appearance}`} {...props}>
      {children.length &&
        children.map((child, index) => (
          <div key={index} className="content-grid__block">
            {child}
          </div>
        ))}
      {!children.length && (
        <div className="content-grid__block">{children}</div>
      )}
    </div>
  );
}
